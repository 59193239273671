import React, { useEffect, useState } from 'react';
import { usePath } from 'hookrouter';
import { useSSO, MySpinner } from 'appshome-react';
import Home from './screens/Home';
import Dashboard from './screens/Dashboard';
import config from './config';

function App() {
  const { isAuthenticated, isAuthenticating, authCode } = useSSO(config.SESSION_JS_URL, config.notes.CLIENT_ID);
  const path = usePath();
  const [user, setUser] = useState(null);
  const [authCodeProcessed, setAuthCodeProcessed] = useState(false);
  useEffect(() => processAuthCode(isAuthenticated, authCode, setUser, setAuthCodeProcessed), [isAuthenticated, authCode]);
  if (isAuthenticating || (!authCodeProcessed && isAuthenticated)) {
    return (<MySpinner />);
  } else {
    if (!isAuthenticated && path === "/") {
      return (<Home />);
    }
    if (!user) {
      return (<MySpinner />)
    }
    return (<Dashboard user={user} />);
  }
}

export default App;
function processAuthCode(isAuthenticated, authCode, setUser, setAuthCodeProcessed) {
  console.log("Inside processAuthCode");
  if (isAuthenticated) {
    fetch(config.notes.API_HOST + "/process_auth_code/" + authCode, { mode: 'cors' })
      .then(res => res.json())
      .then(res => {
        console.log("Processed authCode.");
        setUser(res);
        //setUser(res.Data);
        setAuthCodeProcessed(true);
      })
      .catch(error => {
        setAuthCodeProcessed(true);
        console.log("Error while processing authCode. ", error)
      });
  }
}