import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import {fetchNote, updateNote} from '../services/NotesService';
import {useQueryParams, navigate} from 'hookrouter';
import {Actions, NoteForm, AudioButton, NoteTitle} from './NoteForm';
import {useAudio} from '../hooks/UseAudio';
import {blobToBase64} from '../services/Audio';

export default function EditNote(props) {
    const [title, setTitle] = useState(null);
    const [note, setNote] = useState(null);
    const accessToken = props.user.access_token;
    const noteId = props.noteId;
    const [queryParams] = useQueryParams();
    console.log("Assigned id to noteId: ", noteId);
    const [recorderInitialized, recorder] = useAudio();
    const [recordingStarted, setRecordingStarted] = useState(false);

    useEffect(() => {
        fetchNote(accessToken, noteId).then(res => {
            setNote(res.note.note);
            setTitle(res.note.title);
        })
    }, [noteId, accessToken])
    const handleSave = (exitAfterSave, isTextNote) => {
        if (!exitAfterSave && !isTextNote) {
            return;
        }
        updateNote(accessToken, {'noteId': noteId, 'note': {'title': title, 'note': note, 'mode': queryParams.mode}})
        .then(res => {
            console.log("Update response: ", res);
            if (exitAfterSave) {
                navigate("/");
            }
        })
        .catch(e => console.log("Error occured while updating the note: " + e));
    }
    const handleRecording = async() => {
        if (!recorderInitialized) {
            return;
        }
        if (!recordingStarted) {
            recorder.start();
            setRecordingStarted(true);
        } else {
            const audio = await recorder.stop();
            setRecordingStarted(false);
            const blobText = await blobToBase64(audio.audioBlob);
            setNote(blobText);
            audio.play();
        }
    }

    return (
        <NoteForm title = "Edit note" onSubmit={() => handleSave(true, queryParams.mode !== "voice")}>
            <NoteTitle setTitle={setTitle} title={title} />
            {queryParams.mode !== "voice" ?
                <Form.Group as={Row} controlId="notesForm.ControlTextarea1">
                    <Col xs={12}>
                    <Form.Label>Note:</Form.Label>
                    <Form.Control as="textarea" rows="10" className='w-100' value={note} onChange={(e) => setNote(e.target.value)} />
                    </Col>
                </Form.Group>
            :
                <Form.Group style={{textAlign: 'center'}}>
                    <p>Click start for recording:</p>
                    <AudioButton 
                        onClick = {handleRecording}
                        variant={recordingStarted ? "danger" : "success"}
                        text = {recordingStarted ? "Stop & play" : "Start"} />
                </Form.Group>
            }
            {/* <Actions  saveButtonText = "Update" /> */}
            <Form.Group as={Row} className="d-flex justify-content-between align-items-center">
            <Button  className="d-flex justify-content-center col-xs-12 col-md-3 mr-2 mt-2"   onClick={() => handleSave(false, queryParams.mode !== "voice")}>
                Update
            </Button>
            <Button variant="primary" type="submit" className="d-flex justify-content-center col-xs-12 col-md-3 mr-2 mt-2"   >
                Update & close
            </Button>
            <Button variant="secondary" type="button" className="d-flex justify-content-center col-xs-12 col-md-3 mt-2" onClick={() => navigate("/")}  >
                Cancel
            </Button>
        </Form.Group>
        </NoteForm>
    );
}