import React from 'react';
import { Button, Form} from 'react-bootstrap';
import {useInputFocus} from 'appshome-react';
import {navigate} from 'hookrouter';

export function NoteTitle(props) {
    const titleRef = useInputFocus();
    var inputProps = {
        type: "text",
        placeholder: "Enter title",
        onChange: (e) => props.setTitle(e.target.value),
        ref: titleRef,
        required: true,
    }
    if (props.title) {
        inputProps.value= props.title
    }
    
    return (
        <Form.Group controlId="notesForm.title">
            <Form.Label>Title</Form.Label>
            <Form.Control {...inputProps} />
        </Form.Group>
    );
}
export function AudioButton(props) {
    return (
        <Button 
        onClick={props.onClick} 
        style={{borderRadius: "60px", height: "120px", width: "120px"}}
        variant={props.variant}>
            {props.text}
        </Button>
    );
}
export function NoteForm(props) {
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        event.preventDefault();
        if (props.onSubmit) {
            props.onSubmit();
            event.stopPropagation();
            return true;
        }
    };
    return (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="col-xs-12 col-md-6">
                <h2 className="h4 strong">{props.title}</h2>
                <Form  onSubmit={handleSubmit}>
                    {props.children}
                </Form>
            </div>
        </div>
    );
}
export function Actions(props) {
    return (
        <Form.Group>
            <Button variant="primary" type="submit" className="col-xs-6 col-md-6"   style={{height:65}}>
                {props.saveButtonText}
            </Button>
            <Button variant="secondary" type="button" className="col-xs-6 col-md-6" onClick={() => navigate("/")}  style={{height:65}}>
                Cancel
            </Button>
        </Form.Group>
    )
}
export function NoteData(props) {
    return (
        <Form.Group controlId="notesForm.ControlTextarea1">
            <Form.Label>Note:</Form.Label>
            <Form.Control as="textarea" rows="5" onChange={(e) => props.setNote(e.target.value)} />
        </Form.Group>
    )
}